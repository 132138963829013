import "./App.css";
import HeroPage from "./views/heroPage";
import {ThemeProvider} from "@mui/system";
import GalleryProduct from "./views/galleryProduct";
import Routes from "./routex";
import AppRoutes from "./routex";


function App() {
  return (

      <AppRoutes></AppRoutes>


  );
}

export default App;
